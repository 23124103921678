import React, {FunctionComponent} from "react";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import { Post, Tag } from "../utils/models";
import Subheader from "../components/subheader";
import SEO from "../components/seo";
import PostGrid from "../components/post-grid";

interface TagTemplateProps {
  data: {
    posts: {
      edges: Array<{ node: Post }>;
    }
  };
  pageContext: any;
  location: Location;
}

const TagTemplate: FunctionComponent<TagTemplateProps> = ({ pageContext, data, location}) => {
  const { tag } = pageContext;
  const posts = data.posts.edges.map(node => node.node);

  return (
    <Layout bigHeader={false}>
      <SEO
        title={tag}
        location={location}
        type={`Series`}
      />
      <Subheader title={tag} subtitle={`${posts.length} posts`} backgroundColor={'#000'}/>
      <PostGrid posts={posts} />
    </Layout>
  );
};

export default TagTemplate;

export const query = graphql`
  query($tag: String!) {
    posts: allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "/(posts)/.*\\\\.md$/"},
        frontmatter: {tags: {eq: $tag}}
      },
      sort: {fields: frontmatter___created, order: DESC}
    ) {
      edges {
        node {
          id
          frontmatter {
            title
            path
            categories
            excerpt
            created
            createdPretty: created(formatString: "DD MMMM, YYYY")
          }
          featuredImage {
            childImageSharp {
              sizes(maxWidth: 800, quality: 100) {
                base64
                aspectRatio
                src
                srcSet
                sizes
              }
            }
          }
        }
      }
    }
  }
`;
